import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import './assets/css/base.css' //全局css
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import 'lib-flexible' //自适应  

//节流指令
import prevent from './utils/throttle'
Vue.use(prevent);

//接口
import http from './utils/cesguToken'
Vue.prototype.$http = http

//vuew
import ViewUI from 'view-design';
import 'view-ui-plus/dist/styles/viewuiplus.css'
Vue.use(ViewUI);

//富文本解析
import VueQuillEditor from 'vue-quill-editor'
import 'quill/dist/quill.core.css' // 引入样式
import 'quill/dist/quill.snow.css' // snow theme
import 'quill/dist/quill.bubble.css' // bubble theme
Vue.use(VueQuillEditor, /* { default global options } */)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')