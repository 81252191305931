<template>
  <div id="app">
    <router-view />
  </div>
</template> 
<script>
export default {
  name: 'app',
  created() {
  },
  methods: {
  }
}
</script>
<style lang="less">
#app {
  width: 100%;
  background-color: #f4f8f9;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3);
  border: 2px solid #fff;
  border-radius: 4px;
}
</style> 
